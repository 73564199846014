<template>
  <div class="page-error">
    <n-loader :loading="$var('load')"/>
    <div class="container">
      <div class="wrap">
        <div class="icon-place">
          <n-icon icon="warning"/>
          <div class="text">Не удалось отправить данные. Возможно из-за нестабильного соединения. Данные скоро будут
            отправлены.
          </div>
        </div>
        <div class="button-place">
          <n-button @click="go">Хорошо</n-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import logo from 'assets/logo.png'
import imgWarn from 'assets/warn.svg'

export default {
  name: 'PageError',
  data() {
    return {
      logo,
      imgWarn,
    }
  },
  methods: {
    go() {
      this.$router.push({name: 'history'})
    },
  },
}
</script>

<style scoped lang="scss">
.page-error {
  .container {
    height: calc(100vh - 70px);
  }

  .wrap {
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;

    .icon-place {
      flex: 8;
      width: 300px;
      height: 300px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      .text {
        text-align: center;
        margin-top: 15px;
        color: #FFAF36;
        font-size: 1.2em;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }

  .button-place {
    flex: 0.5;

    ::v-deep .n-button {
      width: 80vw;
      background-color: #57419D;
      border-radius: 32px;
      color: #FFFFFF;
      text-transform: uppercase;
      height: 40px;
    }
  }
}
</style>
